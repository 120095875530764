// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/assets/fonts/GraphikRegular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/assets/fonts/GraphikSemibold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../public/assets/fonts/GraphikMedium.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'GraphikRegular';
  src:
    local('GraphikRegular'),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}
h1 {
  color: red;
}
@font-face {
  font-family: 'GraphikSemiBold';
  src:
    local('GraphikSemiBold'),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}

@font-face {
  font-family: 'GraphikMedium';
  src:
    local('GraphikMedium'),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}

*,
body {
  margin: 0;
  padding: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B;;8DAEmE;AACrE;AACA;EACE,UAAU;AACZ;AACA;EACE,8BAA8B;EAC9B;;8DAEoE;AACtE;;AAEA;EACE,4BAA4B;EAC5B;;8DAEkE;AACpE;;AAEA;;EAEE,SAAS;EACT,YAAY;AACd","sourcesContent":["@font-face {\n  font-family: 'GraphikRegular';\n  src:\n    local('GraphikRegular'),\n    url(../public/assets/fonts/GraphikRegular.otf) format('truetype');\n}\nh1 {\n  color: red;\n}\n@font-face {\n  font-family: 'GraphikSemiBold';\n  src:\n    local('GraphikSemiBold'),\n    url(../public/assets/fonts/GraphikSemibold.otf) format('truetype');\n}\n\n@font-face {\n  font-family: 'GraphikMedium';\n  src:\n    local('GraphikMedium'),\n    url(../public/assets/fonts/GraphikMedium.otf) format('truetype');\n}\n\n*,\nbody {\n  margin: 0;\n  padding: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
